import React from "react";

/**
 * CustomizedBar component renders a single bar in the histogram
 * 
 * @param {Object} props - Component props
 * @param {string} props.fill - Fill color of the bar
 * @param {number} props.y - Y-coordinate for the top of the bar
 * @param {number} props.height - Height of the bar
 * @param {number} props.width - Width of each individual bar segment
 * @param {Array} props.scaleCoordinates - Array of coordinate data for positioning
 * @param {string|number} props.date - Date/timestamp this bar represents
 * @param {boolean} props.isStacked - Whether bars are stacked or side by side
 * @param {number} props.dataOrder - The order of this data series (for unstacked bars)
 * @param {number} props.dataCount - Total number of data series (for unstacked bars)
 * @returns {React.ReactElement|null} - Bar rectangle element or null if coordinates not found
 */
const CustomizedBar = (props) => {
  const { fill, y, height, width, scaleCoordinates, date, isStacked, dataOrder, dataCount } = props;
  // Find the coordinate matching this bar's date
  const coordinates = scaleCoordinates[date];
  
  // Calculate the x position based on whether bars are stacked or not
  const xCoord = isStacked ? coordinates?.x : coordinates?.x + dataOrder * width;
  
  // Calculate the width of the bar - full width if stacked, divided among data series if not
  const barWidth = isStacked ? coordinates?.widthRect : coordinates?.widthRect / dataCount;
  
  // Only render if we have valid coordinates
  return (
      coordinates && (
          <rect 
              x={xCoord} 
              y={y} 
              width={barWidth} 
              height={height} 
              stroke="white" 
              strokeWidth={0.5} 
              fill={fill} 
              // Add data attribute to help tooltip positioning
              data-bar-center={xCoord + (barWidth / 2)}
              data-date={date}
          />
      )
  );
};

export default CustomizedBar;