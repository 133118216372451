import React from 'react';

const CustomizedActiveDot = (props) => {
  const { fill, cy, width, height, dataKey, payload, scaleCoordinates, stroke , handleActiveDotMouseHover, handleActiveDotMouseLeave } = props;
  const coordinate = scaleCoordinates[payload.date];
  return (
      coordinate && (
          <circle
              r={6}
              onMouseOver={() => handleActiveDotMouseHover(dataKey)}
              onMouseLeave={() => handleActiveDotMouseLeave(dataKey)}
              stroke={stroke}
              strokeWidth="1"
              fill={fill}
              width={width}
              height={height}
              cx={coordinate.x}
              cy={cy}
              className="recharts-dot recharts-line-dot"
          />
      )
  );
};

export default CustomizedActiveDot;