import React from 'react';
import { Curve } from 'recharts';
import { generateString } from '../../../helpers/string-helper';

// Component that renders custom lines for a chart using Recharts
const CustomizedLines = ({ formattedGraphicalItems, coordinates }) => {
    // console.info('formattedGraphicalItems', formattedGraphicalItems);
  // Array to store the processed line points and their styles
  const linesPointsToDraw = [];

  // Process each graphical item (line) from the input
  formattedGraphicalItems.forEach((graphicalItems) => {
      // Map through points and update x coordinates based on provided coordinates array
      const points = graphicalItems.props.points.flatMap((point) => {
        const coordinate = coordinates[point.payload.date];
          if (coordinate) {
              // Create new point with updated x coordinate
              const newPoint = { ...point, x: coordinate?.x };
              return newPoint;
          }
          // Skip points that don't have corresponding coordinates
          return [];
      });
      // Store processed points along with the line's stroke color
      linesPointsToDraw.push({ points, stroke: graphicalItems.item.props.stroke });
  });

  // Render Curve components for each line with unique keys
  return linesPointsToDraw.map(({ points, stroke }) => (
      <Curve 
          key={generateString(8)}  // Generate unique key for React rendering
          points={points}          // Points to draw the curve
          stroke={stroke}          // Line color
          strokeWidth={1}          // Line thickness
          fill="none"             // No fill for the curve
      />
  ));
};

export default CustomizedLines;