import moment from 'moment';
import { useTranslation } from 'react-i18next';
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import * as d3 from 'd3';
import { utils, writeFile } from 'xlsx';
import { isNaN } from 'lodash';
import { Button, Empty, Skeleton } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Customized,
    // Curve,
    // Area,
} from 'recharts';
import { useSelector } from 'react-redux';
// import { PlanningContext } from '../../contexts/needs/PlanningContext';
import { generateString } from '../../helpers/string-helper';
import { xAxisDateFormat, xAxisDateScaleFunction } from './CustomHistogramDateXaxis';
import { dateToTimestamp, dateToUTCDate } from '../../helpers/planning';
import { NEED_CHART_DATA, NEED_CHART_TYPE, NEED_HISTOGRAM_TYPE, SLICE_DATA_TYPE } from '../../constants/Needs';
import CustomizedActiveDot from './customized/CustomizedActiveDot';
import CustomizedLines from './customized/CustomizedLines';
import CustomizedDot from './customized/CustomizedDot';
import CustomizedBar from './customized/CustomizedBar';
import { TooltipContent, TooltipLabelDate } from './customized/CustomizedTooltip';
import CustomDateRectangles from './customized/CustomDateRectangles';
import { notificationError } from '../../helpers/notification';
import { selectTabPreferences } from '../../redux/slices/app/tab.slice';
import { HistogramChartContext } from '../../contexts/chart/HistogramChartContext';
import { selectScale } from '../../redux/slices/slicing/activity-calendar.slice';
import { selectChartData, selectChartOptions, selectNeedList } from '../../redux/slices/chart/need-chart.slice';
import getDurationUnitName from '../plannings/gantt_utils';


const SCALE_HEIGHT = 35;
const SCALE_FONT_SIZE = 11;

const StyledContainer = styled.div`
    .recharts-curve.recharts-line-curve {
        display: none;
    }
`;

const parseGanttScales = () => {
    const scaleContainer = document.querySelector('.gantt_task_scale');
    if (!scaleContainer) return { mainScale: [], subScale: [] };
    
    const scaleLines = scaleContainer.querySelectorAll('.gantt_scale_line');
    if (!scaleLines || scaleLines.length < 2) return { mainScale: [], subScale: [] };
    
    // Main scale is typically the first line
    const mainScaleCells = scaleLines[0].querySelectorAll('.gantt_scale_cell');
    // Sub scale is typically the second line
    const subScaleCells = scaleLines[1].querySelectorAll('.gantt_scale_cell');
    
    const parseScaleCells = (cells) => 
        Array.from(cells).map(cell => {
            const style = cell.getAttribute('style');
            const widthMatch = style.match(/width:(\d+)px/);
            const leftMatch = style.match(/left:(\d+)px/);
            const text = cell.textContent;
            const x = leftMatch ? parseInt(leftMatch[1], 10) : 0;
            
            // Get timestamp from position using dhtmlx dateFromPos
            let timestamp = null;
            if (window.ganttInstance && typeof window.ganttInstance.dateFromPos === 'function') {
                const date = window.ganttInstance.dateFromPos(x);
                if (date) {
                    timestamp = dateToTimestamp(date);
                }
            }
            
            return {
                widthRect: widthMatch ? parseInt(widthMatch[1], 10) : 0,
                heightRect: SCALE_HEIGHT,
                text,
                x,
                isCentered: true,
                timestamp
            };
        });
    
    
    return {
        mainScale: parseScaleCells(mainScaleCells),
        subScale: parseScaleCells(subScaleCells)
    };
};

const GanttHistogramChart = () => {
    let showDefaultTooltip;
    let activeDotDataKey;
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const {t} = useTranslation('translation', { keyPrefix: 'needs' });
    const { setChartRef } = useContext(HistogramChartContext);
    const realChartRef = useRef(null);
    const [viewPort, setViewPort] = useState({});
    const [loadingChart, setLoadingChart] = useState(false);
    const tabPreferences = useSelector(selectTabPreferences);
    const scale = useSelector(selectScale);
    const chartData = useSelector(selectChartData);
    const chartOptions = useSelector(selectChartOptions);
    const needList = useSelector(selectNeedList);

    // const [showDefaultTooltip, setShowDefaultTooltip] = useState(true);
    const isHourScale = scale === 'hours';
    const getXAxisArgsForTimeBasedGraph = useCallback(
        (scaleFunction, isSubScale) => {
            setLoadingChart(true);
            if (chartData.length > 0 && scale) {
                const timeUnit = isSubScale
                    ? xAxisDateFormat(generalTranslation)[scale].subScaleUnit
                    : xAxisDateFormat(generalTranslation)[scale].mainScaleUnit;
                const startTime = isHourScale
                    ? dateToUTCDate(moment.utc(chartData[0].date).startOf(timeUnit).subtract(1, timeUnit).valueOf())
                    : moment.utc(chartData[0].date).startOf(timeUnit).valueOf();
                const endTime = isHourScale
                    ? dateToUTCDate(
                          moment
                              .utc(chartData[chartData.length - 1].date)
                              .endOf(timeUnit)
                              .valueOf()
                      )
                    : moment
                          .utc(chartData[chartData.length - 1].date)
                          .endOf(timeUnit)
                          .valueOf();
                const timeScale = d3.scaleTime().domain([startTime, endTime]);
                console.log('🚀 ~ file: GanttHistogramChart.jsx:68 ~ GanttHistogramChart ~ [startTime, endTime]:', [
                    startTime,
                    endTime,
                ]);
                const dateFormat = isSubScale ? xAxisDateFormat(generalTranslation)[scale].subScale : xAxisDateFormat(generalTranslation)[scale].mainScale;
                return {
                    // domain: [startTime, endTime],
                    // type: 'date',
                    tickFormatter: (date) =>
                        isHourScale ? moment.utc(date).format(dateFormat) : moment.utc(date).format(dateFormat),
                    ticks: timeScale.ticks(scaleFunction()).map((date) => dateToTimestamp(date)),
                };
            }
            setLoadingChart(false);
            return {};
        },
        [chartData, scale]
    );

    // useEffect(() => {
    //     setLoadingChart(true);
    // }, [tabPreferences?.gantt_scales]);

    const mainAxisConfig = useMemo(() => {
        if (chartData.length > 0) {
            return getXAxisArgsForTimeBasedGraph(xAxisDateScaleFunction[xAxisDateFormat(generalTranslation)[scale]?.mainScaleUnit], false);
        }
        return {};
    }, [chartData]);

    const loadViewport = useCallback(
        (currentViewPort, from) => {
            // console.log("🚀 ~ file: GanttHistogramChart.jsx:91 ~ GanttHistogramChart ~ from:", from)
            if (chartData.length > 0) {
                const viewPortInit = { ...currentViewPort };
                if (!viewPortInit.minScope) viewPortInit.minScope = 0;
                if (!viewPortInit.maxScope) viewPortInit.maxScope = 60;
                viewPortInit.chartSize = parseInt(
                    document.getElementById('gantt-histogram-container')?.offsetWidth,
                    10
                );
                viewPortInit.chartAreaElement = document.getElementById('gantt-histogram-area');
                viewPortInit.visibleWidthChart = parseInt(
                    document.getElementById('gantt-histogram-area')?.style?.width,
                    10
                );
                viewPortInit.step = viewPortInit.chartSize / chartData.length;
                // viewPortInit.marginLeft = viewPortInit.step * viewPortInit.minScope;
                viewPortInit.marginLeft = window.ganttInstance.posFromDate(
                    new Date(dateToUTCDate(chartData[viewPortInit.minScope]?.date))
                );
                // viewPortInit.marginRight = viewPortInit.step * Math.max(0, chartData.length - viewPortInit.maxScope);
                const lastDatePosition = window.ganttInstance.posFromDate(
                    new Date(dateToUTCDate(chartData[Math.min(viewPortInit.maxScope, chartData.length - 1)]?.date))
                );
                viewPortInit.marginRight = viewPortInit.chartSize - lastDatePosition;
                viewPortInit.chartWidth = lastDatePosition - viewPortInit.marginLeft;

                return viewPortInit;
            }
            return {};
        },
        [chartData]
    );

    // if(Object.keys(viewPort).length <= 0) loadViewport();

    const computeViewPort = useCallback(() => {
        setViewPort((oldViewPort) => {
            let newViewPort = oldViewPort.chartAreaElement
                ? oldViewPort
                : loadViewport({}, 'from totally new viewport');
            const currentChartSize = parseInt(document.getElementById('gantt-histogram-container')?.offsetWidth, 10);
            // console.log("🚀 Before compute", cloneDeep(newViewPort), currentChartSize)
            if (
                newViewPort &&
                (newViewPort?.chartAreaElement.scrollLeft < newViewPort?.marginLeft ||
                    newViewPort?.marginLeft + newViewPort?.chartWidth <
                        newViewPort?.chartAreaElement.scrollLeft + newViewPort?.visibleWidthChart) &&
                currentChartSize === newViewPort?.chartSize
            ) {
                newViewPort.minScope = Math.round(
                    Math.max(0, newViewPort.chartAreaElement.scrollLeft / newViewPort.step - 1)
                );
                newViewPort.maxScope = newViewPort.minScope + 60;
                newViewPort = loadViewport(newViewPort, 'from after compute scope');
            }
            // console.log("🚀 ~ file: GanttHistogramChart.jsx:133 ~ setViewPort ~ newViewPort:", newViewPort)
            setLoadingChart(false);

            return newViewPort;
        });
    }, [viewPort, chartData]);

    // update yaxis when container size change
    const handleOnResize = useCallback(() => {
        if (chartData.length > 0) {
            // console.log('ONRESIZE');
            const newViewPort = loadViewport({}, 'on resize');
            setViewPort(newViewPort);
            setTimeout(() => {
                setChartRef({ ...realChartRef?.current?.state });
                // console.log('compute before resize')
                computeViewPort();
            }, 500);
        }
    }, [realChartRef?.current?.state, chartData]);
    // add processing gantt to wait for width change

    useEffect(() => {
        if (chartData.length > 0) {
            handleOnResize();
            const chartAreaElement = document.getElementById('gantt-histogram-area');
            
            if (chartAreaElement) {
                // We'll use requestAnimationFrame to handle scroll events more efficiently
                let ticking = false;
                
                const handleScroll = () => {
                    if (!ticking) {
                        window.requestAnimationFrame(() => {
                            computeViewPort();
                            ticking = false;
                        });
                        ticking = true;
                    }
                };
                
                // Listen to scroll instead of scrollend, and use our debounced handler
                chartAreaElement.addEventListener('scroll', handleScroll, { passive: true });
                
                // Optional: still listen to scrollend for cleanup
                chartAreaElement.addEventListener('scrollend', () => {
                    if (ticking) {
                        ticking = false;
                    }
                }, { passive: true });
                
                return () => {
                    chartAreaElement.removeEventListener('scroll', handleScroll);
                    chartAreaElement.removeEventListener('scrollend', () => {});
                };
            }
        }
        return () => {};
    }, [chartData]);

    const cumulativeChartData = useMemo(() => {
        const result = [];
        chartData.forEach((data, index) => {
            const cumulativeData = { ...data };
            if (index > 0) {
                Object.keys(data).forEach((key) => {
                    if (key !== 'date') {
                        cumulativeData[key] += result[index - 1][key];
                    }
                });
            }
            result.push(cumulativeData);
        });
        return result;
    }, [chartData]);

    const displayedData = useMemo(
        () =>
            chartOptions && chartOptions.dataType === NEED_CHART_DATA.INSTANTANEOUS
                ? chartData.slice(viewPort?.minScope, viewPort?.maxScope)
                : cumulativeChartData.slice(viewPort?.minScope, viewPort?.maxScope),

        [viewPort?.minScope, viewPort?.maxScope, chartOptions?.dataType, chartData, cumulativeChartData]
    );

    // update yaxis when viewport change
    useEffect(() => {
        setChartRef({ ...realChartRef?.current?.state });
    }, [viewPort, needList, chartOptions?.dataType]);

    const handleActiveDotMouseHover = (lineDataKey) => {
        showDefaultTooltip = false;
        activeDotDataKey = lineDataKey;
    };
    const handleActiveDotMouseLeave = () => {
        showDefaultTooltip = true;
        activeDotDataKey = null;
    };
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length && activeDotDataKey && !showDefaultTooltip) {
            const activeDotPayload = payload.find((i) => i.name === activeDotDataKey);
            return (
                <div className="bg-white border p-2">
                    <TooltipLabelDate label={label} />
                    <TooltipContent payload={activeDotPayload} />
                </div>
            );
        }
        return (
            <div className="bg-white border p-2">
                <TooltipLabelDate label={label} />
                {(payload || []).map((currentPayload) => {
                    const payloadToShow = {...currentPayload};
                    if (!isNaN(currentPayload?.value)) {
                        payloadToShow.value = Number(currentPayload?.value).toFixed(2);
                        payloadToShow.realValue = +Number(currentPayload?.value).toFixed(2);
                    } else {
                        payloadToShow.realValue = 0;
                    }
                    if (chartOptions?.sliceDataType === SLICE_DATA_TYPE.EFFORT) {
                        payloadToShow.value = ''.concat(payloadToShow.value).concat('/').concat(getDurationUnitName(chartOptions.effortTimeUnitId));
                    }
                    return <TooltipContent key={generateString(5)} payload={payloadToShow} />;
                })}
            </div>
        );
    };
    const computeScaleDates = (data, tickFunction, scaleUnit) => {
        if (data.length > 0) {
            const currentData = data;
            const timeUnit = xAxisDateFormat(generalTranslation)[scale][scaleUnit];
            const startTime =
                timeUnit === 'hours'
                    ? moment.utc(currentData[0].date).startOf(timeUnit).subtract(1, timeUnit).valueOf()
                    : moment.utc(currentData[0].date).valueOf();
            const endTime = moment
                .utc(currentData[currentData.length - 1].date)
                .endOf(xAxisDateFormat(generalTranslation)[scale].mainScaleUnit)
                .valueOf();
            const domain = [startTime, endTime];

            const timeScale = d3.scaleUtc().domain(domain);
            const dates = [currentData[0].date];

            // const dates = [];
            dates.push(...timeScale.ticks(tickFunction()).map((date) => moment.utc(date).startOf('minute').valueOf()));
            dates.push(
                moment
                    .utc(currentData[currentData.length - 1].date)
                    .endOf(xAxisDateFormat(generalTranslation)[scale].mainScaleUnit)
                    .valueOf()
            );
            return dates;
        }
        return [];
    };
    const mainScaleDates = useMemo(
        () =>
            computeScaleDates(
                displayedData,
                xAxisDateScaleFunction[xAxisDateFormat(generalTranslation)[scale]?.mainScaleUnit],
                'mainScaleUnit'
            ),
        [displayedData]
    );
    const subScaleDates = useMemo(
        () =>
            computeScaleDates(
                displayedData,
                xAxisDateScaleFunction[xAxisDateFormat(generalTranslation)[scale]?.subScaleUnit],
                'subScaleUnit'
            ),
        [displayedData]
    );

    const computeScaleCoordinates = (dates, scaleType, isSubScale = false) => {
        if (dates.length > 0) {
            let dateFormat;
            if (isSubScale) {
                const stringFormat = xAxisDateFormat(generalTranslation)[scale].dhtmlxSubScaleFormat;
                if (stringFormat) {
                    dateFormat = { formatter: 'dhtmlx', format: stringFormat };
                } else {
                    dateFormat = { formatter: 'moment', format: xAxisDateFormat(generalTranslation)[scale][scaleType] };
                }
            } else {
                dateFormat = { formatter: 'dhtmlx', format: xAxisDateFormat(generalTranslation)[scale].dhtmlxMainScaleFormat };
            }
            // const dateFormat = xAxisDateFormat(generalTranslation)[scale][scaleType];
            const timeUnit = xAxisDateFormat(generalTranslation)[scale][`${scaleType}Unit`];
            let initialX = viewPort?.marginLeft;
            const rectCoordinates = dates.flatMap((date, index) => {
                if (index > 0 && window.ganttInstance) {
                    // const lastDatePosition = window.ganttInstance.posFromDate(new Date((dates[index - 1])));
                    const lastDatePosition = window.ganttInstance.posFromDate(
                        new Date(dateToUTCDate(dates[index - 1]))
                    );
                    // const datePosition = window.ganttInstance.posFromDate(new Date(date));
                    const datePosition = window.ganttInstance.posFromDate(new Date(dateToUTCDate(date)));
                    let widthRect = datePosition - lastDatePosition;
                    // fix mainScale date when hours
                    if (date === chartData[0].date && timeUnit === 'hours') {
                        widthRect = viewPort.step;
                    }
                    if ((timeUnit === 'hours' && widthRect <= 0) || widthRect < 0) {
                        return [];
                    }
                    let text = '';
                    if (dateFormat.formatter === 'dhtmlx') {
                        text =
                            timeUnit === 'hours'
                                ? window.ganttInstance.date.date_to_str(dateFormat.format)(new Date(dateToUTCDate(date)))
                                : window.ganttInstance.date.date_to_str(dateFormat.format)(new Date(dateToUTCDate(dates[index - 1])));
                    } else {
                        text =
                            timeUnit === 'hours'
                                ? moment.utc(date).format(dateFormat.format)
                                : moment.utc(dates[index - 1]).format(dateFormat.format);
                    }
                    // ADJUSTMENT OF TIMELINE AND CHART
                    // if (timeUnit !== 'hours') {
                    //     initialX -= 2;
                    // }
                    const res = {
                        widthRect,
                        heightRect: SCALE_HEIGHT,
                        text,
                        x: initialX,
                        isCentered: true,
                        timestamp: isSubScale ? date : date[index - 1],
                        // isCentered: index !== 1 && index !== dates.length - 1,
                    };
                    initialX += widthRect;
                    return res;
                }
                return [];
            });
            return rectCoordinates;
        }
        return [];
    };

    const [scaleCoordinates, setScaleCoordinates] = useState({ mainScale: [], subScale: [] });
    
    // Update scale coordinates when gantt updates
    useEffect(() => {
        const updateScales = () => {
            const scales = parseGanttScales();
            setScaleCoordinates(scales);
        };
        
        // Initial parse
        updateScales();
        
        // Set up mutation observer to detect changes in gantt_task_scale
        const scaleContainer = document.querySelector('.gantt_task_scale');
        if (scaleContainer) {
            const observer = new MutationObserver(updateScales);
            observer.observe(scaleContainer, { 
                childList: true, 
                subtree: true,
                attributes: true,
                attributeFilter: ['style'] 
            });
            
            return () => observer.disconnect();
        }
        return () => {};
    }, []);
    
    /**
     * Converts an array of scale coordinates to a lookup object for O(1) access
     * @param {Array} coordinates - Array of scale coordinate objects
     * @param {boolean} shiftForLineChart - Whether to shift associations for line charts
     * @returns {Object} Object with timestamps as keys for fast lookup
     */
    const createCoordinatesLookup = (coordinates, shiftForLineChart = false) => {
        if (!coordinates || !coordinates.length) return {};
        
        const coordinatesLookup = coordinates.reduce((lookup, coord, index) => {
            // For line charts, shift the association one position back
            if (shiftForLineChart && index > 0) {
                /* eslint-disable-next-line */
                lookup[coordinates[index - 1].timestamp] = {
                    ...coord,
                    index
                };
            } else {
                // Use the timestamp as key for direct access
                /* eslint-disable-next-line */
                lookup[coord.timestamp] = {
                    ...coord,
                    index
                };
            }
            return lookup;
        }, {});
        
        // Handle the last point correctly to ensure the line ends at zero
        if (shiftForLineChart && coordinates.length > 0) {
            const lastCoord = coordinates[coordinates.length - 1];
            // Create a virtual end point for the last segment
            coordinatesLookup[lastCoord.timestamp] = {
                ...lastCoord,
                index: coordinates.length,
                // Ensure the end point is properly positioned at the far right
                x: lastCoord.x + lastCoord.widthRect,
                isEndPoint: true
            };
        }
        
        return coordinatesLookup;
    };

    const mainScaleCoordinates = useMemo(
        () => {
            let coordinates = [];
            if (scaleCoordinates.subScale) {

                coordinates = scaleCoordinates.subScale
            } else {
                coordinates = computeScaleCoordinates(mainScaleDates, 'mainScale');
            }
            
            // Create the lookup object with shift for line charts
            const isLineChart = chartOptions?.chartType === NEED_CHART_TYPE.LINE;
            const lookup = createCoordinatesLookup(coordinates, isLineChart);
            
            // Return both the array (for iteration) and lookup (for direct access)
            return {
                array: coordinates || [],
                lookup
            };
        },
        [scaleCoordinates.subScale, chartOptions?.chartType, mainScaleDates]
    );
    
    const subScaleCoordinates = useMemo(
        () => scaleCoordinates.mainScale.length > 0 ? scaleCoordinates.mainScale : computeScaleCoordinates(subScaleDates, 'subScale', true),
        [scaleCoordinates.mainScale, subScaleDates, viewPort?.step]
    );

    const exportData = useCallback(() => {
        try {
            const sourceData = (chartOptions && chartOptions.dataType === NEED_CHART_DATA.INSTANTANEOUS
                ? chartData
                : cumulativeChartData)
            // create a new workbook
            const wb = utils.book_new();
                // Header
            const sheetHeader = [t('needs')]
            sourceData.forEach((entry) => {
                sheetHeader.push(new Date (dateToUTCDate(entry.date)));
            });
            // Histogram Data
            const sheetData = needList.filter((i) => i.selected).reduce((old, current) => {
                const lineData = old;
                lineData[current.name] = [current.name];
                return lineData;
            }, {});
            const needNames = Object.keys(sheetData); // used for accessing data from chart data
            sourceData.forEach((entry) => {
                needNames.forEach((need) => {
                    sheetData[need].push(entry[need]);
                })
            });
            const dataToExport = [sheetHeader];
            dataToExport.push(...Object.values(sheetData));
            // convert the data to a worksheet
            const taskSheetData = utils.aoa_to_sheet(dataToExport, {UTC: true});
            taskSheetData ['!cols'] = sheetHeader.map(() => ({wch: 20}));
            // add the worksheet to the workbook
            utils.book_append_sheet(wb, taskSheetData, 'Histogram Data');
            // save the workbook as a file
            writeFile(wb, `Histogram.xlsx`);
        } catch (error) {
            notificationError('', generalTranslation('generic_error'));
        }
    }, [chartData, cumulativeChartData, chartOptions, needList]);

    /* eslint-disable react/prop-types, react/no-unknown-property */
    return chartData.length > 0 ? (
        <StyledContainer className="w-full h-full">
            {loadingChart ? (
                <Skeleton active title={false} paragraph={{ rows: 10 }} size="large" />
            ) : (
                <>
                <ResponsiveContainer
                    debounce={800}
                    height="100%"
                    width="100%"
                    className="w-full h-full"
                    onResize={() => handleOnResize()}
                >
                    <ComposedChart
                        barCategoryGap={0}
                        barGap={0}
                        margin={{ left: viewPort?.marginLeft, right: viewPort?.marginRight, bottom: 2 * SCALE_HEIGHT }}
                        data={displayedData}
                        ref={realChartRef}
                    >
                        <CartesianGrid
                            stroke="#828282"
                            id="gantt-histogram-grid"
                            width={viewPort?.chartWidth*2}
                            verticalCoordinatesGenerator={() => mainScaleCoordinates.array.map((c) => c.x)}
                        />
                        <YAxis hide domain={['auto', 'dataMax + 10']} />
                        <XAxis
                            dataKey="date"
                            scale={`${chartOptions.chartType === NEED_CHART_TYPE.BAR ? 'time' : undefined}`}
                            interval={0}
                            fontSize={SCALE_FONT_SIZE}
                            {...mainAxisConfig}
                            dx={viewPort?.step / 2}
                            dy={-(SCALE_HEIGHT + SCALE_FONT_SIZE) / 2}
                            height={SCALE_HEIGHT}
                            padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
                            tickSize={SCALE_HEIGHT}
                            hide
                        />

                        <Tooltip isAnimationActive={false} cursor={false} content={CustomTooltip} offset={10} />
                        {chartOptions.chartType === NEED_CHART_TYPE.BAR &&
                            needList
                                .filter((i) => i.selected)
                                .map((need, index, filteredList) => (
                                    <Bar
                                        key={generateString(2)}
                                        dataKey={need.name}
                                        stackId={
                                            chartOptions.histogramType === NEED_HISTOGRAM_TYPE.STACKED ? 'bar' : null
                                        }
                                        name={need.name}
                                        isAnimationActive={false}
                                        fill={need.color}
                                        shape={
                                            <CustomizedBar
                                                scaleCoordinates={mainScaleCoordinates.lookup}
                                                dataOrder={index}
                                                dataCount={filteredList.length}
                                                isStacked={chartOptions.histogramType === NEED_HISTOGRAM_TYPE.STACKED}
                                            />
                                        }
                                    />
                                ))}
                        {chartOptions.chartType === NEED_CHART_TYPE.LINE &&
                            needList
                                .filter((i) => i.selected)
                                .map((need) => (
                                    <Line
                                        key={generateString(2)}
                                        activeDot={
                                            <CustomizedActiveDot
                                                scaleCoordinates={mainScaleCoordinates.lookup}
                                                handleActiveDotMouseHover={() => handleActiveDotMouseHover()}
                                                handleActiveDotMouseLeave={() => handleActiveDotMouseLeave()}
                                            />
                                        }
                                        onMouseLeave={() => handleActiveDotMouseLeave(need.name)}
                                        dataKey={need.name}
                                        dot={
                                            <CustomizedDot
                                                scaleCoordinates={mainScaleCoordinates.lookup}
                                                handleActiveDotMouseLeave={() => handleActiveDotMouseLeave()}
                                            />
                                        }
                                        stroke={need.color}
                                        name={need.name}
                                        isAnimationActive={false}
                                    />
                                ))}
                        {chartOptions.chartType === NEED_CHART_TYPE.LINE && (
                            <Customized component={<CustomizedLines coordinates={mainScaleCoordinates.lookup} />} />
                        )}
                        <Customized
                            component={<CustomDateRectangles isMainScale coordinates={mainScaleCoordinates.array} />}
                        />
                        <Customized component={<CustomDateRectangles coordinates={subScaleCoordinates} />} />
                    
                    </ComposedChart>
                </ResponsiveContainer>
                <Button id='export-histogram-excel-button' size="small" className="hide absolute top-0" onClick={() => exportData()}/>
                </>
            )}
        </StyledContainer>
    ) : (
        <Empty />
    );
};

export default GanttHistogramChart;
